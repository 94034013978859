
import chartsMixin from '../mixins/charts';
import uiMixin from '../mixins/ui';

export default {
  name: 'ChartsView',
  mixins: [
    chartsMixin,
    uiMixin,
  ],
};
